/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

// gatsby-browser.js
import { navigate } from "gatsby"

export const onRouteUpdate = ({ location }) => {
  // Check if there is a hash in the URL
  const hash = location.hash

  // Define hash-based redirects
  const hashRedirects = {
    "#overview": "/about-sm/",
    "#symptoms": "/recognizing-sm/symptoms/",
    "#burden-of-sm": "/recognizing-sm/living-with-sm/",
    "#screen-for-sm": "/diagnosing-sm/",
    "#case-studies": "/sm-profiles-case-studies/",
    "#find-a-lab": "/diagnosing-sm/",
    "#diagnose-sm": "/diagnosing-sm/",
  }

  // If the hash is in the redirect list, navigate to the new path
  if (hash && hashRedirects[hash]) {
    navigate(hashRedirects[hash], { replace: true })
  }
}
